import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from '@reach/router';
import _STATES_ from '../../../components/caregiver-signup/states'
import { Button, ButtonGroup, FormGroup, InputGroup, Radio, RadioGroup, Checkbox } from '@blueprintjs/core';
import { Row, Col } from 'react-flexbox-grid'
import Layout from '../../../components/dfa-theme/layout/'
import yearlyImage from '../../../images/caregiver-signup-thumbnail.webp'
import { Controller, useForm } from 'react-hook-form';
import { loadReCaptcha, ReCaptcha, } from 'react-recaptcha-v3'
import axios from 'axios';
import './caregiver-signup.scss';
import { Link } from 'gatsby';
import facebookIcon from '../../../images/social-icons/facebook-icon.svg';
import instagramIcon from '../../../images/social-icons/instagram-icon.svg';
import youtubeIcon from '../../../images/social-icons/youtube-icon.webp';
import tiktokIcon from '../../../images/social-icons/tiktok-icon.svg';
import redditIcon from '../../../images/social-icons/reddit-icon.svg';

const TEST_SITE_KEY = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
const PRODUCTION_SITE_KEY = "6LcsqPIjAAAAAPg7jo7PuJU28PnadTAnQ9WWh5x6";

const RECAPTCHA_SITE_KEY = process.env.NODE_ENV === 'development'
  ? TEST_SITE_KEY
  : PRODUCTION_SITE_KEY;

const metaTags = {
  description: 'Sign up to receive more information from SUPPRELIN® LA (histrelin acetate) subcutaneous implant.',
  keywords: 'Homepage',
  title: 'Sign up | SUPPRELIN® LA (histrelin acetate)'
}

function RequestPage() {
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const recaptchaRef = useRef(null);
  const location = useLocation();

  const [urlParams, setUrlParams] = useState({
    campaignId: null,
    tacticId: null,
    jobId: null,
    subscriberId: null
  });

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors }
  } = useForm({
    mode: "onSubmit"
  });

  useEffect(() => {
    // Check if this is a new navigation to the page
    if (location.state && location.state.resetForm) {
      setSubmitted(false);
      reset();
      // Clear the state so it doesn't reset on every render
      window.history.replaceState({}, document.title)
    }
  }, [location, reset]);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      setErrorMessage('Please fill in all required fields correctly.');
      setIsSubmitting(false);
    }
  }, [errors]);


  const handleFormSubmit = async (data) => {
    // console.log('handleFormSubmit called with data:', data);
    const dataToSend = {
      ...data,
      Address2: '',
      Over18: data.Over18 === 'Y' ? 'Y' : 'N',
      CorporateOptin: data.CorporateOptin === 'Y' ? 'Y' : 'N',
      BrandOptin: 'Y',
      // CampaignId: urlParams.campaignId || '',
      // TacticId: urlParams.tacticId || '',
      // JobId: urlParams.jobId || '',
      // SubscriberId: urlParams.subscriberId || '',
      Q5: data.Q5
    };

    const response = await axios.post(process.env.SUP_SFMC_API_ENDPOINT, dataToSend, {
      headers: {
        AuthKey: process.env.SUP_SFMC_HEADER_VALUE,
      }
    });
    // console.log('API Response:', response);
    return response;
  };

  useEffect(() => {
    // console.log('useEffect for ReCaptcha loading called');
    // Load ReCaptcha
    loadReCaptcha(RECAPTCHA_SITE_KEY, () => {
      console.log('recaptcha loaded');
    });

    // Extract URL parameters
    const searchParams = new URLSearchParams(window.location.search);
    setUrlParams({
      campaignId: searchParams.get('cid') || null,
      tacticId: searchParams.get('tid') || null,
      jobId: searchParams.get('jid') || null,
      subscriberId: searchParams.get('sid') || null
    });

    // Cleanup function
    return () => {
      const script = document.getElementById('recaptcha-script');
      if (script) {
        script.remove();
      }

      const recaptchaElems = document.getElementsByClassName('grecaptcha-badge');
      if (recaptchaElems.length) {
        recaptchaElems[0].remove();
      }
    };
  }, []);


  const handleSubmitBtnClick = () => {
    // console.log('Submit button clicked');
    handleSubmit(async (data) => {
      setIsSubmitting(true);
      try {
        if (!recaptchaRef.current) {
          throw new Error('ReCaptcha not loaded');
        }

        // Use execute() instead of executeAsync()
        recaptchaRef.current.execute();

        // The token will be received in the verifyCallback function
        // So we need to set up a promise to wait for it
        const token = await new Promise((resolve, reject) => {
          window.captchaCallback = (token) => {
            resolve(token);
          };
          // Add a timeout in case the callback is never called
          setTimeout(() => reject(new Error('ReCaptcha timeout')), 5000);
        });

        // console.log('ReCaptcha token obtained:', token);
        await handleFormSubmit({ ...data, RecaptchaToken: token });
        // console.log('Form submission successful');
        setSubmitted(true);
      } catch (error) {
        console.error('Form submission error:', error);
        setErrorMessage('An error occurred during form submission. Please try again.');
      } finally {
        setIsSubmitting(false);
      }
    })();
  };

  return (

    <Layout meta={metaTags} pageClassName='patients-caregiver-signup-page'>
      {submitted &&
        <Row>
          <Col xs={12}>
            <div className="thank-you-message" id='thank-you'>
              <h1>Thank you for ordering your Caregiver Kit</h1>

              <div className="btn-wrap">
                <Link to='/' className='btn btn-primary'>Return to SupprelinLA.com</Link>
              </div>

              <h1>Follow SUPPRELIN<sup>&reg;</sup> LA on social to learn more</h1>

              <div className="social-links">
                <ul>
                  <li>
                    <a href="https://www.facebook.com/supprelinla/" target="_blank" rel="noopener noreferrer">
                      <img src={facebookIcon} alt="Facebook Icon" loading='lazy' />
                    </a>
                  </li>

                  <li className='youtube-icon'>
                    <a href="https://www.youtube.com/channel/UC21b8dSG9qYB34rKLATIK0A" target="_blank" rel="noopener noreferrer">
                      <img src={youtubeIcon} alt="Youtube Icon" loading='lazy' />
                    </a>
                  </li>

                  <li>
                    <a href="https://www.instagram.com/supprelin_la_histrelin_acetate/" target="_blank" rel="noopener noreferrer">
                      <img src={instagramIcon} alt="Instagram Icon" loading='lazy' />
                    </a>
                  </li>

                  <li>
                    <a href="https://www.tiktok.com/@supprelin_la" target="_blank" rel="noopener noreferrer">
                      <img src={tiktokIcon} alt="TikTok Icon" loading='lazy' />
                    </a>
                  </li>

                  <li>
                    <a href="https://www.reddit.com/user/Supprelin_LA/" target="_blank" rel="noopener noreferrer">
                      <img src={redditIcon} alt="Reddit Icon" loading='lazy' />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      }

      {!submitted &&
        <div className="caregiver-signup-page">
          <Row>
            <Col xs={12} md={6} lg={6}>
              <h1>Order your Caregiver Kit your way</h1>
              <p>Sign up now to receive an all-in-one kit for caregivers of children with central precocious puberty (CPP). <strong>CPP & My Family: A Caregiver’s Guide</strong> is available by email or mail and includes resources about CPP, SUPPRELIN<sup>&reg;</sup> LA treatment, and more to provide support throughout your journey.</p>
              <div className="hide-in-mobile">
                <p>Parent/Caregiver: Please enter your information below (not your child’s).</p>
              </div>
              <div className="hide-in-mobile">
                <p>All fields are required.</p>
              </div>
            </Col>
            <Col xs={12} md={6} lg={6}>
              <img src={yearlyImage} alt="Caregiver's Guide" />
            </Col>
            <Col xs={12} md={9}>
              <div className="block-form-content">
                {errorMessage && <div className="error-message">{errorMessage}</div>}
                <>
                  <div className='patients-signup-form'>
                    <div className="show-in-mobile">
                      <p>Parent/Caregiver: Please enter your information below (not your child’s).</p>
                      <p>All fields are required.</p>
                    </div>
                    <Controller
                      control={control}
                      name='Q4'
                      render={({ field: { value } }) => (
                        <FormGroup label="Please select your preferred method of delivery:">
                          <RadioGroup
                            inline
                            onChange={e => {
                              reset({ 'Q4': e.target.value });
                            }}
                            selectedValue={value}
                          >
                            <Radio
                              value="Digital"
                              label='Email – receive a digital kit to your inbox'
                            />
                            <Radio
                              value="Physical"
                              label='Mail – receive a physical kit to your home'
                            />
                          </RadioGroup>
                        </FormGroup>
                      )}
                    />
                  </div>

                  {watch('Q4') === 'Digital' &&
                    <div className="patients-signup-form">
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        name='FirstName'
                        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                          <FormGroup
                            className={`form-control ${error ? 'bp3-intent-danger' : ''}`}
                            label="First Name"
                            labelFor="text-firstName"
                            labelInfo=""
                            inline
                          >
                            <InputGroup large onChange={onChange} onBlur={onBlur} value={value || ''} />
                          </FormGroup>
                        )}
                      />
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        name='LastName'
                        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                          <FormGroup
                            className={`form-control ${error ? 'bp3-intent-danger' : ''}`}
                            label="Last Name"
                            labelFor="text-lastName"
                            labelInfo=""
                            inline
                          >
                            <InputGroup onChange={onChange} onBlur={onBlur} value={value || ''} />
                          </FormGroup>
                        )}
                      />
                      <Controller
                        control={control}
                        rules={{
                          required: true,
                          pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        }}
                        name='Email'
                        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                          <FormGroup
                            className={`form-control ${error ? 'bp3-intent-danger' : ''}`}
                            label="Email Address"
                            labelFor="text-email"
                            labelInfo=""
                            inline
                          >
                            <InputGroup onChange={onChange} onBlur={onBlur} value={value || ''} />
                          </FormGroup>
                        )}
                      />
                      <FormGroup
                        label={<><b>Yes,</b> I would like to receive information in the future about SUPPRELIN<sup>®</sup> LA and related health information.</>}
                      >
                        <Controller
                          control={control}
                          rules={{ required: true }}
                          name='Over18'
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <div className={`checkbox-wrapper ${error ? 'bp3-intent-danger' : ''}`}>
                              <Checkbox
                                checked={value === 'Y'}
                                onChange={(e) => onChange(e.target.checked ? 'Y' : '')}
                                label='I certify that I am 18 years of age or older.'
                              />
                            </div>
                          )}
                        />

                        <Controller
                          control={control}
                          rules={{ required: false }}  // Changed to false
                          name='CorporateOptin'
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <div className={`checkbox-wrapper`}>
                              <Checkbox
                                checked={value === 'Y'}
                                onChange={(e) => onChange(e.target.checked ? 'Y' : 'N')}
                                label='Yes, I would also like to receive information in the future about Endo products, programs, and services that may be of interest to me.'
                              />
                            </div>
                          )}
                        />
                      </FormGroup>


                      <Controller
                        control={control}
                        rules={{ required: true }}
                        name='Q5'
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <FormGroup
                            label={<span><i>Please share the status of your journey:</i></span>}
                            className={`${error ? 'bp3-intent-danger' : ''}`}
                          >
                            <RadioGroup
                              inline
                              onChange={onChange}
                              selectedValue={value}
                            >
                              <Radio
                                value="not-diagnosed-not-treated"
                                label='My child has not been diagnosed with or started treatment for CPP.'
                              />
                              <Radio
                                value="diagnosed-not-treated"
                                label='My child has been diagnosed with CPP but has not yet started treatment.'
                              />
                              <Radio
                                value="diagnosed-treated"
                                label='My child has been diagnosed with CPP and has already started treatment.'
                              />
                            </RadioGroup>
                          </FormGroup>
                        )}
                      />
                      <ButtonGroup>
                        <Button
                          intent="primary"
                          large
                          text="Submit"
                          onClick={handleSubmit(handleSubmitBtnClick)}
                          disabled={isSubmitting || Object.keys(errors).length > 0}
                        />
                      </ButtonGroup>
                    </div>
                  }

                  {watch('Q4') === 'Physical' &&
                    <div className="patients-signup-form">
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        name='FirstName'
                        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                          <FormGroup
                            className={`form-control ${error ? 'bp3-intent-danger' : ''}`}
                            label="First Name"
                            labelFor="text-firstName"
                            labelInfo=""
                            inline
                          >
                            <InputGroup onChange={onChange} onBlur={onBlur} value={value || ''} />
                          </FormGroup>
                        )}
                      />
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        name='LastName'
                        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                          <FormGroup
                            className={`form-control ${error ? 'bp3-intent-danger' : ''}`}
                            label="Last Name"
                            labelFor="text-lastName"
                            labelInfo=""
                            inline
                          >
                            <InputGroup onChange={onChange} onBlur={onBlur} value={value || ''} />
                          </FormGroup>
                        )}
                      />
                      <Controller
                        control={control}
                        rules={{
                          required: true,
                          pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        }}
                        name='Email'
                        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                          <FormGroup
                            className={`form-control ${error ? 'bp3-intent-danger' : ''}`}
                            label="Email Address"
                            labelFor="text-email"
                            labelInfo=""
                            inline
                          >
                            <InputGroup onChange={onChange} onBlur={onBlur} value={value || ''} />
                          </FormGroup>
                        )}
                      />
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        name='Address1'
                        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                          <FormGroup
                            className={`form-control ${error ? 'bp3-intent-danger' : ''}`}
                            label="Street Address"
                            labelFor="text-streetAddress"
                            labelInfo=""
                            inline
                          >
                            <InputGroup onChange={onChange} onBlur={onBlur} value={value || ''} />
                          </FormGroup>
                        )}
                      />
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        name='City'
                        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                          <FormGroup
                            className={`form-control ${error ? 'bp3-intent-danger' : ''}`}
                            label="City"
                            labelFor="text-city"
                            labelInfo=""
                            inline
                          >
                            <InputGroup onChange={onChange} onBlur={onBlur} value={value || ''} />
                          </FormGroup>
                        )}
                      />

                      <Controller
                        control={control}
                        rules={{ required: true }}
                        name='State'
                        render={({ field, fieldState: { error } }) => (
                          <FormGroup
                            className={`form-select ${error ? 'bp3-intent-danger' : ''}`}
                            label="State"
                            labelFor="text-practiceName"
                            labelInfo=""
                          >
                            <div className="bp3-select bp3-large">
                              <select className="select-report" {...field}>
                                <option defaultValue value="">Choose a State</option>
                                {
                                  _STATES_.map(item => {
                                    return <option value={item['alpha-2']} key={item['alpha-2']}>{item.name}</option>
                                  })
                                }
                              </select>
                            </div>
                          </FormGroup>
                        )}
                      />
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        name='ZipCode'
                        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                          <FormGroup
                            className={`form-control ${error ? 'bp3-intent-danger' : ''}`}
                            label="ZIP Code"
                            labelFor="text-zip"
                            labelInfo=""
                            inline
                          >
                            <InputGroup onChange={onChange} onBlur={onBlur} value={value || ''} inputMode="numeric" pattern="[0-9]*" />
                          </FormGroup>
                        )}
                      />

                      <Controller
                        control={control}
                        rules={{ required: true }}
                        name='Q3'
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <FormGroup
                            label="Please select your preferred language:"
                            className={`${error ? 'bp3-intent-danger' : ''}`}
                          >

                            <RadioGroup
                              inline
                              onChange={onChange}
                              selectedValue={value}
                            >
                              <Radio
                                value="English"
                                label='English'
                              />
                              <Radio
                                value="Spanish"
                                label='Spanish'
                              />
                            </RadioGroup>
                          </FormGroup>
                        )}
                      />
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        name='Email2'
                        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                          <FormGroup
                            className={`form-control ${error ? 'bp3-intent-danger' : ''}`}
                            label="Email Address"
                            labelFor="text-email"
                            labelInfo=""
                            inline
                          >
                            <InputGroup onChange={onChange} onBlur={onBlur} value={value || ''} />
                          </FormGroup>
                        )}
                      />
                      <FormGroup
                        label={<><b>Yes,</b> I would like to receive information in the future about SUPPRELIN<sup>®</sup> LA and related health information.</>}
                      >
                        <Controller
                          control={control}
                          rules={{ required: true }}
                          name='Over18'
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <div className={`checkbox-wrapper ${error ? 'bp3-intent-danger' : ''}`}>
                              <Checkbox
                                checked={value === 'Y'}
                                onChange={(e) => onChange(e.target.checked ? 'Y' : '')}
                                label='I certify that I am 18 years of age or older.'
                              />
                            </div>
                          )}
                        />

                        <Controller
                          control={control}
                          rules={{ required: false }}  // Changed to false
                          name='CorporateOptin'
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <div className="checkbox-wrapper">
                              <Checkbox
                                checked={value === 'Y'}
                                onChange={(e) => onChange(e.target.checked ? 'Y' : 'N')}
                                label='Yes, I would also like to receive information in the future about Endo products, programs, and services that may be of interest to me.'
                              />
                            </div>
                          )}
                        />
                      </FormGroup>


                      <Controller
                        control={control}
                        rules={{ required: true }}
                        name='Q5'
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <FormGroup
                            label={<span><i>Please share the status of your journey:</i></span>}
                            className={`${error ? 'bp3-intent-danger' : ''}`}
                          >
                            <RadioGroup
                              className={`${error ? 'bp3-intent-danger' : ''}`}
                              inline
                              onChange={onChange}
                              selectedValue={value}
                            >
                              <Radio
                                value="not-diagnosed-not-treated"
                                label='My child has not been diagnosed with or started treatment for CPP.'
                              />
                              <Radio
                                value="diagnosed-not-treated"
                                label='My child has been diagnosed with CPP but has not yet started treatment.'
                              />
                              <Radio
                                value="diagnosed-treated"
                                label='My child has been diagnosed with CPP and has already started treatment.'
                              />
                            </RadioGroup>
                          </FormGroup>
                        )}
                      />
                      <ButtonGroup>
                        <Button
                          intent="primary"
                          large text="Submit"
                          onClick={handleSubmitBtnClick}
                          disabled={isSubmitting || Object.keys(errors).length > 0}
                        />
                      </ButtonGroup>
                    </div>
                  }
                  <ReCaptcha
                    ref={recaptchaRef}
                    sitekey={RECAPTCHA_SITE_KEY}
                    size="invisible"
                    badge="bottomright"
                    verifyCallback={(token) => {
                      if (window.captchaCallback) {
                        window.captchaCallback(token);
                      }
                    }}
                  />
                </>
              </div>
            </Col>
          </Row>
          {watch('Q4') &&
            <Row>
              <Col xs={12}>
                <p><strong>Endo USA, Inc. understands that your privacy is important. By providing your name, address, and other requested information, you are giving Endo USA, Inc. and other parties working with us permission to communicate with you about SUPPRELIN<sup>&reg;</sup> LA or other products, services, and offers from Endo USA, Inc. We will not sell your name or other personal information to any party for its marketing use. To view the Privacy Policy, please visit <a className='underline' href='https://www.endo.com/privacy-legal' target="_blank" rel="noreferrer noopener">www.endo.com/privacy-legal</a>.</strong></p>
              </Col>
            </Row>
          }
        </div>
      }
    </Layout>
  )
}

export default RequestPage;